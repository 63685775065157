import {ObjectType} from '@sinclair/typebox/value/is';
import {RESPONSE_STATUS_CODE} from 'core/constants';
import {setResponse404StatusCode} from 'core/exceptions/NotFoundException';
import redirect from 'core/next/controllers/redirect.json';
import {getRuntimeConfig} from 'core/next/helpers';
import {wrappedInitialProps} from 'core/next/store';
import {TAppInitialController} from 'core/redux/types';
import {ICustomError} from 'core/types';
import {categoriesController} from 'modules/categories/controller';
import {commonController} from 'modules/common/controllers';
import {menuController} from 'modules/menu/controller';
const MAIN_HOST = getRuntimeConfig().NEXT_PUBLIC_MAIN_HOST;

export const commonPageController: TAppInitialController<void> = async (store, ctx) => {
    await commonRedirectController(store, ctx);
};

export const commonRedirectController: TAppInitialController<void> = async (_, ctx) => {
    const redirectObj: ObjectType = redirect;

    if (!MAIN_HOST || !ctx.asPath) {
        return;
    }

    const destination = redirectObj[`${MAIN_HOST}${ctx.asPath}`] as string;

    if (Boolean(destination)) {
        ctx.res
            ?.writeHead(RESPONSE_STATUS_CODE.FOUND, {
                location: destination,
            })
            .end();
    }
};

export const withBasicData = (cb: TAppInitialController<any>) => {
    return wrappedInitialProps(async (store, ctx) => {
        if (ctx.req) {
            await Promise.all([
                commonPageController(store, ctx),

                categoriesController(store, ctx),
                commonController(store, ctx),
                menuController(store, ctx),
            ]);
        }

        try {
            const data = await cb(store, ctx);

            return data;
        } catch (e) {
            if (RESPONSE_STATUS_CODE.NOT_FOUND === (e as ICustomError)?.statusCode) {
                setResponse404StatusCode(ctx);

                return {
                    statusCode: RESPONSE_STATUS_CODE.NOT_FOUND,
                };
            }
        }

        return {};
    });
};
